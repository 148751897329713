import React from 'react';
import PropTypes from 'prop-types';
import ShoppingCartAdd from '../../table/ShoppingCartAdd';
import ProductCustomization from '../../table/ProductCustomization';
import Price from '../../../general/Price';
import withDataHOC from '../../../dataProvider/withDataHOC';
import {priceByProductCount} from '../../../../lib/project';
import {GLOBAL_DATA} from '../../../../constants/globalData';
import {ProductVariants, TradeProduct} from '../../../../constants/propTypesDefinitions';
import {Trans, t} from '@lingui/macro';
import ProductVariantsSelect from '../../../project/ProductVariantsSelect';

/**
 * @fero
 */

class CustomerPriceAndAddCart extends React.PureComponent {
    static propTypes = {
        numberOfItems: PropTypes.number.isRequired,
        onChangeNumberOfItems: PropTypes.func.isRequired,
        stockId: PropTypes.number,
        productData: TradeProduct.isRequired,
        variants: ProductVariants,
        onVariantChange: PropTypes.func,
    };

    render() {
        const {productLabel, productData, stockId, variants, onVariantChange, onChangeNumberOfItems, numberOfItems} = this.props;
        
        const tax = Number(productData.tax_rate);
        const reload = this.props[GLOBAL_DATA.RELOAD_DATA];
        const onFinish = () => {
            reload([GLOBAL_DATA.PRODUCT_DETAILS, GLOBAL_DATA.CART_CURRENT])
        };
        if (productData.id_form != null) {
            return <div>
                <ProductCustomization
                    product={productData}
                    label={<Trans>Nastavenie možností</Trans>}
                />
                { productData.is_form_required == 1 ? 
                    <div>
                        <Trans>Pre kúpu produktu je potrebné vybrať možnosti.</Trans>
                    </div>
                    : 
                    <ShoppingCartAdd
                        className="pt-3"
                        numberOfItems={numberOfItems}
                        onChangeNumberOfItems={onChangeNumberOfItems}
                        onFinish={onFinish}
                        product={productData}
                        stockId={stockId}
                        size="large"
                        inputClassName="detail-shopping-cart-input"
                        label={<span className="mr-2"><Trans>Do košíka</Trans></span>}
                    />
                }
            </div>;
        }
        else {
            const price = priceByProductCount(productData.prices, numberOfItems, null, productData.stock_options, stockId);
            return <div>
                { variants != null && Array.isArray(variants) ? 
                    <React.Fragment>
                        <span className="mr-2 text-secondary">
                            <Trans>Vyberte variant:</Trans>
                        </span>
                        <ProductVariantsSelect
                            variants={variants}
                            value={productData}
                            onChange={onVariantChange}
                            reload={onFinish}
                            className="full-size-width ml-2"
                        />
                    </React.Fragment>
                    : null
                }
                <div className="d-flex align-items-end overflow-hidden flex-wrap">
                    <h3 className="d-flex align-items-center">
                        <span className="mr-2 text-secondary">
                            <Trans>Cena:</Trans>
                        </span>
                        <Price
                            price={price}
                            currency={productData.id_currency}
                            priceMultiplier={(numberOfItems * (1 + tax))}
                            nullOption={<Trans>na dopyt</Trans>}
                        />
                    </h3>
                    {price != null ?
                        <span className="ml-2">
                            {'('}
                            <Price
                                price={price}
                                currency={productData.id_currency}
                                priceMultiplier={numberOfItems}
                                withVAT={false}
                                nullOption={<Trans>na dopyt</Trans>}
                            />
                            {')'}
                    </span> : null}
                </div>
                <ShoppingCartAdd
                    className="my-2"
                    numberOfItems={numberOfItems}
                    onChangeNumberOfItems={onChangeNumberOfItems}
                    onFinish={onFinish}
                    product={productData}
                    stockId={stockId}
                    size="large"
                    inputClassName="detail-shopping-cart-input"
                    label={<span className="mr-2"><Trans>Do košíka</Trans></span>}
                />
            </div>;
        }
    }

}

export default withDataHOC([GLOBAL_DATA.RELOAD_DATA])(CustomerPriceAndAddCart);