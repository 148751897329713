import React from 'react';
import PropTypes from 'prop-types';
import SimpleTable from '../../general/SimpleTable';
import {ProductParameter} from '../../../constants/propTypesDefinitions';
import { formatQuantity } from '../../../lib/formatting';

/**
 * @fero
 */

class ProductParamsTable extends React.PureComponent {
    static propTypes = {
        productParams: PropTypes.arrayOf(ProductParameter.isRequired),
    };

    render() {
        const {productParams} = this.props;
        return <SimpleTable
            data={productParams}
            colDefs={
                [
                    {
                        dataKey: 'name',
                    },
                    {
                        render: (data) => {
                            const {val, unit} = data;
                            return formatQuantity(val, unit);
                        }
                    }
                ]
            }
        />;
    }

}

export default ProductParamsTable;