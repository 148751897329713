import React from 'react';
import PropTypes from 'prop-types';
import SimpleTable from '../../general/SimpleTable';

/**
 * @fero
 */

class ProductSpecialParamsTable extends React.PureComponent {
    static propTypes = {
        lines: PropTypes.arrayOf(PropTypes.shape({
            label: PropTypes.node.isRequired,
            value: PropTypes.node,
        }).isRequired).isRequired
    };

    render() {
        const {lines} = this.props;
        const linesWithValue = lines.filter(line => line.value != null);
        return <SimpleTable
            data={linesWithValue}
            colDefs={
                [
                    {dataKey: 'label'},
                    {dataKey: 'value'}
                ]
            }
        />;
    }

}

export default ProductSpecialParamsTable;